import {infoMessage} from "@/services/messages";
import storage from '../front-store';
import http from './http_client';
export const baseErrHandler = async (callback) => {
    try {
        storage.commit('setLoading', true);
        await callback();
    } catch (e) {
        console.log('api error', e);
        infoMessage(e?.response?.data?.message || e?.response?.data?.error?.message, 'error');
    } finally {
        storage.commit('setLoading', false);
    }
};

export const updateUser = async (data) => {
    const res = await http.postAuth(`${http.apiUrl()}/users/update` , data);
    let this_user = res?.data?.user || null;
    return this_user;
};

export const generateRoute = async (data) => {
    const res = await http.postAuth(`${http.apiUrl()}/routes/new-route-generate` , data);
    return res;
};

export const storeRoute = async (data) => {
    const res = await http.postAuth(`${http.apiUrl()}/routes/new-route-store` , data);
    return res;
};

export const getRouteById = async (id) => {
    const res = await http.getAuth(`${http.apiUrl()}/routes/${id}`);
    return res;
};