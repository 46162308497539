<template>
  <div class="component">
    <div class="top_line text-left">
      <h2 class="title pb-5 text-2xl fw-b">Routing</h2>
    </div>
    <div class="body flex align-middle">
      <form class='inner' @submit.prevent="">
        <div class='title_line flex justify-items-center align-middle gap-20'>
          <div class=''>Engine</div>
          <select
              v-model="api_version"
              class=""
              @change="updateUser"
              id="api_version">
            <option disabled :value="api_version">{{ api_version }}</option>
            <option
                :value="'v1'">
              v1
            </option>
            <option
                :value="'v2'">
              v2
            </option>
          </select>
        </div>
      </form>
    </div>
    <form @submit.prevent="" class='title_line flex gap-5 flex flex-col'  v-if='api_version === "v2"'>
      <div class='flex flex-col'>
        <div class='text-left'>Avoid segments separate by comma</div>
        <input @input='validateSegments' type='text' class='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
               placeholder='Enter segments' v-model='here_segments'>
      </div>
      <div class='flex gap-2' >
        <input type='checkbox' v-model='driver_use_segments'/>
        <span>Use for drivers</span>
      </div>
      <button @click='updateUserHereSegments' class='green-white' :disabled='here_segments.length >= 1000'>Save segments</button>
    </form>
  </div>
</template>

<style scoped lang="scss">
form, .body {
  width: 300px;
  margin-left: 0;
}
</style>
<script>
import {baseErrHandler, updateUser} from "@/services/api";

export default {
  name: "Routing",
  data() {
    return {
      api_version: null,
      here_segments: '',
      driver_use_segments: false
    }
  },
  methods: {
    async updateUser() {
      await baseErrHandler(async () => {
        const this_user = await updateUser({api_version: this.api_version});
        await localStorage.setItem('user', JSON.stringify(this_user));
      });
    },
    async updateUserHereSegments() {
      await baseErrHandler(async () => {
        const this_user = await updateUser({here_segments: this.here_segments, driver_use_segments: this.driver_use_segments});
        await localStorage.setItem('user', JSON.stringify(this_user));
      });
    },
    validateSegments() {
      if (this.here_segments.length >= 1000) {
        this.here_segments = this.here_segments.slice(0, 999);
      }
    }
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.api_version = user.api_version || 'v1';
    this.here_segments = user.here_segments || '';
    this.driver_use_segments = user.driver_use_segments || false;
  }
}
</script>